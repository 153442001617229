body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@keyframes wiggle { 
  0% { transform: rotateZ(0deg);} 
  25% { transform: rotateZ(1deg);} 
  50% { transform: rotateZ(0deg);} 
  75% { transform: rotateZ(-1deg);} 
  100% { transform: rotateZ(0deg);} 
}

.wiggle { 
   animation-name: wiggle; 
   animation-timing-function: linear; 
   animation-iteration-count:infinite;
   animation-duration: 0.2s;
}

@keyframes rotate { 
  0% { transform: rotateZ(0deg);} 
  50% { transform: rotateZ(180deg);} 
  70% { transform: rotateZ(0deg);} 
  100% { transform: rotateZ(360deg);} 
}

.rotate { 
   animation-name: rotate; 
   animation-timing-function: ease-in-out; 
   animation-iteration-count:infinite;
   animation-duration: 10s;
}

.blur {
  animation: blur 6s ease-in infinite;
}

@keyframes blur {
  50% {
    text-shadow:
      0px 0px 10px white,
      0px 0px 10px white;
  }
}